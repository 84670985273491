import React from "react";
import { Link } from "react-router-dom";
import OurServices from "./OurServices";
import Testimonial from "./Testimonial";

const Home = () => {
  return (
    <>
      {/* Home Image start */}
      <div class="slider-area ">
        <div class="slider-active">
          {/* <!-- Single Slider --> */}
          <div class="single-slider slider-height d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-7 col-md-8">
                  <div class="hero__caption">
                    <span data-animation="fadeInLeft" data-delay=".1s">
                      Committed to success
                    </span>
                    <h1 data-animation="fadeInLeft" data-delay=".5s">
                      We help to grow your business
                    </h1>
                    <p data-animation="fadeInLeft" data-delay=".9s">
                      Become a member and take advantage of our fund raising
                      program
                      <br /> Raise upwards of 10 million.
                    </p>
                    {/* <!-- Hero-btn --> */}
                    <div
                      class="hero__btn"
                      data-animation="fadeInLeft"
                      data-delay="1.1s"
                    >
                      <Link to="/fund-raiser" className="btn hero-btn">
                        More Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Image end */}

      <OurServices />

      <section
        class="wantToWork-area w-padding2 section-bg bg-danger "
        // data-background="assets/img/gallery/section_bg03.jpg"
      >
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-xl-7 col-lg-9 col-md-8">
              <div class="wantToWork-caption wantToWork-caption2">
                <h2>
                  Are you Ready
                  <br /> To Start Your Growth Journey?
                </h2>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4">
              <Link to="/contact-us" className="btn btn-black f-right">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Testimonial />
    </>
  );
};

export default Home;
