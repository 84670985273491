import React from "react";

const Testimonial = () => {
  return (
    <>
      <div
        class="testimonial-area testimonial-padding bg-test"
        data-background="assets/img/gallery/section_bg04.jpg"
      >
        <div class="container ">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-10 col-lg-10 col-md-9">
              <div class="h1-testimonial-active">
                {/* <!-- Single Testimonial --> */}
                <div class="single-testimonial text-center">
                  {/* <!-- Testimonial Content --> */}
                  <div class="testimonial-caption ">
                    <div class="testimonial-top-cap">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        width="67px"
                        height="49px"
                      >
                        <path
                          fill-rule="evenodd"
                          fill="rgb(240, 78, 60)"
                          d="M57.053,48.209 L42.790,48.209 L52.299,29.242 L38.036,29.242 L38.036,0.790 L66.562,0.790 L66.562,29.242 L57.053,48.209 ZM4.755,48.209 L14.263,29.242 L0.000,29.242 L0.000,0.790 L28.527,0.790 L28.527,29.242 L19.018,48.209 L4.755,48.209 Z"
                        />
                      </svg>
                      <h1 className="display-4 text-white">
                        Success is not final; failure is not fatal; It is the
                        courage to continue that counts.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
