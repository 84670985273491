import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyA7aKxbbK-XGi26VBHoLznUyO5OfVkwN58",
  authDomain: "bsiim-180fe.firebaseapp.com",
  databaseURL: "https://bsiim-180fe.firebaseio.com",
  projectId: "bsiim-180fe",
  storageBucket: "bsiim-180fe.appspot.com",
  messagingSenderId: "817254444662",
  appId: "1:817254444662:web:c7fc187a9275c12fb23957",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();

export const addLead = (lead) => {
  return db.collection("leads").add({
    name: lead.name,
    email: lead.email,
  });
};

export const createUser = async (
  email,
  password,
  name,
  cardNumber,
  history,
  appState,
  changeState
) => {
  try {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        userCredential.user.getIdToken().then((token) => {
          changeState("token", token);
        });

        db.collection("members")
          .doc(userCredential.user.uid)
          .set({
            email: email,
            password: password,
            name: name,
            cardNumber: cardNumber,
          })
          .then(() => {
            alert("Account Created Successfully.");
            history.push("/bsiim-login");
          });
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        changeState("registerError", true);
        changeState("registerErrorMessage", errorMessage);
      });
  } catch (error) {
    alert("There was an error creating your account.");
  }
};

export const signIn = async (email, password, history, changeState) => {
  try {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        history.push("/shop");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        changeState("loginErrorMessage", errorMessage);
        changeState("loginError", true);
      });
  } catch (error) {
    alert("Error Logging In, Contact Admin Immediately.");
  }
};

// export const resetPassword = async (email) => {
//   try {
//     const response = await auth.sendPasswordResetEmail(email);
//   } catch (error) {
//     alert("Error Sending reset link.");
//   }
// };

export const getAllCardNumbers = async () => {
  var numbers = [];
  const numberRef = db.collection("cardNumbers");
  const snapshot = await numberRef.get();
  snapshot.forEach((doc) => {
    numbers.push(doc.data().number);
  });
  return numbers;
};

export const signOut = async (history) => {
  try {
    auth.signOut().then(() => {
      history.push("/");
    });
  } catch (error) {
    console.log(error);
  }
};
