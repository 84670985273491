import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../../Context/AppState'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { appState } = useContext(AppContext);
    const authenticated = appState.isauthenticated;
    return (
        <Route 
            {...rest}
            render={props => 
                authenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/bsiim-login' />
                )
            }
        />
    )
}

export default PrivateRoute
