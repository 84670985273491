const BASE_URL = 'https://bsiim-api.herokuapp.com/';

let endpoints = {
    Authentication:{
        RegisterCard: BASE_URL + 'fundraising/register-member',
        Login: BASE_URL + 'fundraising/login'
    }
}

// export default {
//     endpoints,
//     BASE_URL
// }

module.exports = {
    endpoints: endpoints
}