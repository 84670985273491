import React from "react";
import { Link } from "react-router-dom";
// import { AppContext } from '../../Context/AppState';

const Navigationbar = () => {
  // const { appState } = useContext(AppContext);
  // const authorized = appState.isAuthenticated;

  return (
    <div>
      <div class="header-area">
        <div class="main-header ">
          <div class="header-top d-none d-lg-block">
            <div class="container">
              <div class="col-xl-12">
                <div class="row d-flex justify-content-between align-items-center">
                  <div class="header-info-left">
                    <ul>
                      <li>
                        <i class="far fa-clock"></i> Mon - SAT: 9.00 am - 4.00
                        pm
                      </li>
                      <li>Sun: Closed</li>
                    </ul>
                  </div>
                  <div class="header-info-right">
                    <ul class="header-social">
                      <li>
                        <a href="https://www.facebook.com">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com">
                          <i class="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="https://www.google.com">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-bottom  header-sticky">
            <div class="container">
              <div class="row align-items-center">
                {/* <!-- Logo --> */}
                <div class="col-xl-2 col-lg-2">
                  <div class="">
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <h1 className="display-4">BSIIM</h1>
                    </Link>
                  </div>
                </div>
                <div class="col-xl-10 col-lg-10">
                  <div class="menu-wrapper  d-flex align-items-center justify-content-end">
                    {/* <!-- Main-menu --> */}
                    <div class="main-menu d-none d-lg-block">
                      <nav>
                        <ul id="navigation">
                          <li>
                            <Link to="/" style={{ textDecoration: "none" }}>
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about"
                              style={{ textDecoration: "none" }}
                            >
                              About
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/services"
                              style={{ textDecoration: "none" }}
                            >
                              Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/bsiim-register"
                              style={{ textDecoration: "none" }}
                            >
                              Shop
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact-us"
                              style={{ textDecoration: "none" }}
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                {/* <!-- Mobile Menu --> */}
                <div class="col-12">
                  <div class="mobile_menu d-block d-lg-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigationbar;
