import React from 'react';
// import { AppContext } from '../../../Context/AppState';

const ShopHome = () => {
    // const { appState, changeState } = useContext(AppContext)

    // const [displayName, setDisplayName] = useState(appState.userName);

    return (
        <div>
            <div className="d-flex justify-content-center">
                <h1>Coming Soon.</h1>
            </div>
        </div>
    )
}

export default ShopHome
