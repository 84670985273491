import React, { useState, useContext, useEffect, useCallback } from "react";
import { Form, Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllCardNumbers } from "../../Firestore";
import { AppContext } from "../../../Context/AppState";
import axios from 'axios';
import { endpoints } from '../../Utils/URL'
import { addToast } from '../../Utils/toastNotifications'

const RegisterCard = (props) => {
  const { appState, changeState } = useContext(AppContext);

  const initialState = {
    name: "",
    email: "",
    phone:'',
    cardNumber: "",
    password: "",
  };

  // const history = useHistory();

  const [localState, setLocalState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [cardNumbers, setCardNumbers] = useState([])

  const handleChange = (value) => (e) => {
    setLocalState({
      ...localState,
      [value]: e.target.value,
    });
  };

  const getNumbers = useCallback(async () => {
    const numbers = await getAllCardNumbers();
    setCardNumbers(numbers);
  }, [])

  useEffect(() => {
    const runOnComponentMount = async () => {
      await getNumbers()
    }
    runOnComponentMount();
  }, [getNumbers])

  const register = async (event) => {
    event.preventDefault();
    const phone = localState.phone;
    const number = phone.substr(1);
    const newPhone = `+234${number}`;
    localState.phone = newPhone
    setLoading(true);
    const match = cardNumbers.includes(localState.cardNumber)
    if(match){
      try {
        const response = await axios.post(endpoints.Authentication.RegisterCard, localState)
        if(response.status === 200){
          setLoading(false)
          addToast('Card Registered Successfully','success')
          props.history.push('/bsiim-login');
        } else {
          setLoading(false)
          addToast('Card Registration Unsuccessful. Please try again','error')
        }
      } catch (error) {
        setLoading(false);
        addToast('Registration Currently Unavailable','info')
      }
    } else {
      setLoading(false);
      addToast('Card number does not exist. Please try again','error')
    }
    
  };

  return (
    <div className="d-flex justify-content-center align-items-center ">
      <Form id="register-form" className="mt-5 mb-5" style={{ width: "450px" }}>
        <h1 className="text-center mt-3 mb-3 " style={{ fontSize: "60" }}>
          Register Your BSIIM Card
        </h1>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Full Name"
            value={localState.name}
            onChange={handleChange("name")}
            disabled={loading}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={localState.email}
            onChange={handleChange("email")}
            disabled={loading}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            placeholder="Phone Number"
            value={localState.phone}
            onChange={handleChange("phone")}
            disabled={loading}
            maxLength={11}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Card Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="BSIIM Discount Card Number"
            value={localState.cardNumber}
            onChange={handleChange("cardNumber")}
            disabled={loading}
            maxLength={12}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={localState.password}
            onChange={handleChange("password")}
            disabled={loading}
          />
        </Form.Group>
        {appState.registerError ? (
          <Alert
            variant="danger"
            onClick={() => changeState("registerError", false)}
          >
            {" "}
            {/* {appState.registerErrorMessage}{" "} */}
            Password should be at least 6 characters
          </Alert>
        ) : (
          <span></span>
        )}
        <Form.Group>
          <button
            className="btn hero-btn"
            onClick={register}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Register"
            )}
          </button>
          <Form.Text className="text-muted">
            Already Registered?{" "}
            <Link
              to="/bsiim-login"
              className="text-primary"
              style={{ textDecoration: "none" }}
            >
              {" "}
              Click Here{" "}
            </Link>
          </Form.Text>
        </Form.Group>
      </Form>
    </div>
  );
};

export default RegisterCard;
