import React from "react";
import Header from "../../Common/Header";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <Header title="Our Services" />
      <div class="categories-area section-padding30">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/* <!-- Section Tittle --> */}
              <div class="section-tittle mb-70">
                <span>Services</span>
                <h2>All Our Services</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-cat text-center mb-50">
                <div class="cat-icon">
                  <span class="flaticon-development"></span>
                </div>
                <div class="cat-cap">
                  <h5>
                    <Link to='/'>SG-Business Model </Link>
                  </h5>
                  <p>
                    You have done so much to upgrade and accelerate your
                    business and it's time to become more real-world relevant.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-cat text-center mb-50">
                <div class="cat-icon">
                  <span class="flaticon-result"></span>
                </div>
                <div class="cat-cap">
                  <h5>
                    <Link to='/'>JS-Resources Model</Link>
                  </h5>
                  <p>
                    Our model will help millions acheive their global career
                    potential, ensuring 100% candidate employed after
                    participation.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-cat text-center mb-50">
                <div class="cat-icon">
                  <span class="flaticon-team"></span>
                </div>
                <div class="cat-cap">
                  <h5>
                    <Link to='/fundraising'>Fund Raising Program</Link>
                  </h5>
                  <p>
                    This is a program to help your organization meet their
                    demanding needs and complete their projects in the shortes
                    time possible.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-cat text-center mb-50">
                <div class="cat-icon">
                  <span class="flaticon-result"></span>
                </div>
                <div class="cat-cap">
                  <h5>
                    <Link to='/'>Investment Model</Link>
                  </h5>
                  <p>
                    This is a program to help your organization meet their
                    demanding needs and complete their projects in the shortest
                    time possible.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-cat text-center mb-50">
                <div class="cat-icon">
                  <span class="flaticon-team"></span>
                </div>
                <div class="cat-cap">
                  <h5>
                    <Link to='/'>Agro-Business Solution</Link>
                  </h5>
                  <p>
                    Using our marketing model, creating market, to increase
                    off-takers and investment opportunity for farmers and those
                    who are interested in investing in farming or Agro-business.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-cat text-center mb-50">
                <div class="cat-icon">
                  <span class="flaticon-development"></span>
                </div>
                <div class="cat-cap">
                  <h5>
                    <Link to='/'>Real Estate Solution </Link>
                  </h5>
                  <p>
                    Our stress housing solution will determine our members' home
                    plan in 2-5 years.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
