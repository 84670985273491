import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AppProvider } from "./Context/AppState";

import {
  ToastProvider,
  DefaultToastContainer,
} from 'react-toast-notifications';

// Component imports
import Footer from "./Components/Common/Footer";
import NavigationBar from "./Components/Common/Navbar";
import Home from "./Components/Pages/Home/Home";
// import Business from "./Components/Pages/Business/Business";
// import Education from "./Components/Pages/Education/Education";
// import Resource from "./Components/Pages/Resources/Resource";
import About from "./Components/Pages/About/About";
import Services from "./Components/Pages/Services/Services";
import Contact from "./Components/Pages/Contact/Contact";
import RegisterCard from "./Components/Pages/Shop/RegisterCard";
import Login from "./Components/Pages/Shop/Login";

// Routing
// import AuthRoute from './Components/Common/AuthRoute';
import PrivateRoute from './Components/Common/PrivateRoute';

// Shop Components
import ShopHome from './Components/Pages/Shop/ShopHome'

// CSS imports
import "./Components/assets/css/bootstrap.min.css";
import "./Components/assets/css/owl.carousel.min.css";
import "./Components/assets/css/slicknav.css";
import "./Components/assets/css/flaticon.css";
import "./Components/assets/css/animate.min.css";
import "./Components/assets/css/magnific-popup.css";
import "./Components/assets/css/fontawesome-all.min.css";
import "./Components/assets/css/themify-icons.css";
import "./Components/assets/css/slick.css";
import "./Components/assets/css/nice-select.css";
import "./Components/assets/css/style.css";
import "./App.css";

function App() {

  //adds the context to window so we can access anywhere
  window.__react_toast_provider = React.createRef();

  //create a default container so we can override the styles
  const ToastContainer = (props) => (
    <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
  );

  return (
    <ToastProvider components={{ ToastContainer }} ref={window.__react_toast_provider} >
    <AppProvider>
      <BrowserRouter>
        <NavigationBar />
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/business" component={Business} /> */}
          {/* <Route exact path="/education" component={Education} /> */}
          {/* <Route exact path="/resource" component={Resource} /> */}
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/bsiim-register" component={RegisterCard} />
          <Route exact path="/bsiim-login" component={Login} />
          <PrivateRoute exact path='/shop-home' component={ShopHome} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </AppProvider>
    </ToastProvider>
  );
}

export default App;
