import React from "react";

const Header = (props) => {
  return (
    <div class="slider-area2">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{props.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
