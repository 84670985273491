import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bg-light text-white pb-3">
      <Container>
        <Row className="pt-5 pb-5">
          <Col md={3}>
            {/* <img
              src={require("../Images/amethyst.png")}
              alt="Logo"
              height={50}
              width={100}
            /> */}
            <h2 className="display-5">BSIIM CONSULTANTS</h2>
          </Col>
          <Col md={3}>
            <hr style={{ backgroundColor: "white" }} />
            <h4 className="mt-5 mb-3">ADDRESS</h4>
            <p>Suite D88, 3rd Floor, IDE Plaza</p>
            <p>Plot 484, Obafemi Awolowo Way</p>
            <p>Utako District</p>
            <p>Abuja, Nigeria</p>
          </Col>
          <Col md={3}>
            <hr style={{ backgroundColor: "blue" }} />
            <h4 className="mt-5 mb-3">CONTACT</h4>
            <p>Tel: +2348175637632 +2347030632712 </p>
            <p>Email: info@bsiimplatform.com </p>
          </Col>
          <Col md={3}>
            <hr style={{ backgroundColor: "black" }} />
            <h4 className="mt-5 mb-3">FEATURES</h4>
            <ul id="navigation">
                          <li>
                            <Link to="/" style={{ textDecoration: "none" }}>
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about"
                              style={{ textDecoration: "none" }}
                            >
                              About
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/services"
                              style={{ textDecoration: "none" }}
                            >
                              Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/bsiim-register"
                              style={{ textDecoration: "none" }}
                            >
                              Shop
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact-us"
                              style={{ textDecoration: "none" }}
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
          </Col>
        </Row>
        
        <div className="text-center" >
        <p > Powered by Amethyst International </p>
            <img src={require('../Images/amethyst.png')} alt='Logo' height={100} width={200} />
            </div>
      </Container>
    </div>
  );
};

export default Footer;
