import React, { useState, useContext } from "react";
import { Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { signIn } from "../../Firestore";
import { AppContext } from "../../../Context/AppState";
import axios from 'axios'
import { endpoints } from '../../Utils/URL'
import { addToast } from '../../Utils/toastNotifications'

const Login = (props) => {
  const { appState, changeState } = useContext(AppContext);

  const initialState = {
    email: "",
    password: "",
    cardNumber: '',
  };

  // const history = useHistory();

  const [localState, setLocalState] = useState(initialState);

  const handleChange = (value) => (e) => {
    setLocalState({
      ...localState,
      [value]: e.target.value,
    });
  };

  const login = async (event) => {
    event.preventDefault();
    changeState("error", false);
    try {
      await axios.post(endpoints.Authentication.Login, localState)
        changeState('isAuthenticated', true);
        props.history.push('/shop-home');
      
    } catch (error) {
      addToast('Error Logging In. Try Again', 'info')
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center ">
      <Form id="login-form" className="mt-5 mb-5" style={{ width: "350px" }}>
        <h1 className="text-center mt-3 mb-3 " style={{ fontSize: "60" }}>
          LOGIN
        </h1>
        {appState.loginError ? (
          <Alert
            variant="danger"
            onClick={() => changeState("error", false)}
            dismissible
            onClose={() => changeState("error", false)}
          >
            Username and/or Password Incorect
          </Alert>
        ) : (
          <div></div>
        )}
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={localState.email}
            onChange={handleChange("email")}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Card Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="BSIIM Card Number"
            value={localState.cardNumber}
            onChange={handleChange("cardNumber")}
          />
        </Form.Group>
        <Form.Group>
          <button className="btn hero-btn" onClick={login}>
            Submit
          </button>
          <Form.Text className="text-muted">
            Not Yet Registered?{" "}
            <Link
              to="/bsiim-register"
              className="text-primary"
              style={{ textDecoration: "none" }}
            >
              {" "}
              Click Here{" "}
            </Link>
          </Form.Text>
          <Form.Text className="text-muted">
            <Link to="/recover-password" className="text-primary">
              Forgot Password?
            </Link>
          </Form.Text>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Login;
