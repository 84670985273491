import React, { useState } from "react";
import Header from "../../Common/Header";
import { addLead } from "../../Firestore";

const Contact = () => {
  const initialState = {
    name: "",
    email: "",
  };

  const [localState, setLocalState] = useState(initialState);

  const handleChange = (value) => (e) => {
    setLocalState({
      ...localState,
      [value]: e.target.value,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (localState.name === "" || localState.email === "") {
      alert("Please enter your name and/or email.");
    } else {
      try {
        const payload = localState;
        const response = await addLead(payload);
        console.log(response);
        alert(
          "Thank you for getting in touch with us. We will contact you as soon as possible."
        );
        setLocalState({
          name: "",
          email: "",
        });
      } catch (error) {
        alert("Error Sending Contact.");
      }
    }
  };

  return (
    <>
      <Header title="Contact Us" />
      <section className="contact-section">
        <div className="container">
          <div class="row">
            <div class="col-12">
              <h2 class="contact-title">Get in Touch</h2>
            </div>
            <div class="col-lg-8">
              <form
                class="form-contact contact_form"
                id="contactForm"
                novalidate="novalidate"
                onSubmit={submit}
              >
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        class="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        value={localState.name}
                        onChange={handleChange("name")}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter your name'"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        class="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        value={localState.email}
                        onChange={handleChange("email")}
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter email address'"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <button
                    type="submit"
                    class="button button-contactForm boxed-btn"
                    // onClick={submit}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div class="col-lg-3 offset-lg-1">
              <div class="media contact-info">
                <span class="contact-info__icon">
                  <i class="ti-home"></i>
                </span>
                <div class="media-body">
                  <h3>Suite D88, 3rd Floor, IDE Plaza</h3>
                  <p>Plot 484, Obafemi Awolowo Way</p>
                  <p>Utako District</p>
                  <p>Abuja, Nigeria</p>
                </div>
              </div>
              <div class="media contact-info">
                <span class="contact-info__icon">
                  <i class="ti-tablet"></i>
                </span>
                <div class="media-body">
                  <h3>+2348175637632</h3>
                  <p>Mon to Fri 9am to 4pm</p>
                </div>
              </div>
              <div class="media contact-info">
                <span class="contact-info__icon">
                  <i class="ti-email"></i>
                </span>
                <div class="media-body">
                  <h3>info@bsiimplatform.com</h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
